<template>
  <div>
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          {{ myTitle }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <Heading heading="h4" :content="myTitle">
      <el-tooltip
        slot="button"
        class="item"
        effect="dark"
        content="Create New Company"
        placement="top"
      >
        <el-button type="success" size="mini" @click="showCompanyModalButton">
          <i class="fa fa-plus"></i>
        </el-button>
      </el-tooltip>
    </Heading>
    <small> View {{ myTitle }} </small>
    <hr />
    <div class="text-center mt-3 mb-3">
      <el-pagination
        background
        @current-change="setPage"
        :page-size="pageSize"
        :current-page="page"
        :total="resultsTotal"
        :pager-count="10"
        layout="prev, pager, next"
      >
      </el-pagination>
    </div>
    <el-table class="mt-4" :data="companies" style="min-width: 100%" border>
      <el-table-column label="Name" min-width="100">
        <template slot-scope="scope">
          <b> {{ scope.row.name }} </b>
        </template>
      </el-table-column>
      <el-table-column label="Address" min-width="150">
        <template slot-scope="scope">
          <a v-if="scope.row.host" :href="scope.row.host">{{
            scope.row.host
          }}</a>
          <span v-else> - </span>
        </template>
      </el-table-column>
      <el-table-column label="Admin" min-width="150">
        <template slot-scope="scope">
          <router-link
            v-if="scope.row.user"
            :to="{
              name: 'AdminViewUser',
              params: {
                id: scope.row.user.id
              }
            }"
          >
            <b>
              {{
                user.displayName(
                  scope.row.user.first_name,
                  scope.row.user.last_name
                )
              }}
            </b>
            <br />
            <span>{{ scope.row.user.email }}</span>
          </router-link>
        </template>
      </el-table-column>
      <el-table-column label="Introduction" min-width="100">
        <template slot-scope="scope">
          {{ scope.row.introduction }}
        </template>
      </el-table-column>
      <el-table-column label="Users" width="80">
        <template slot-scope="scope">
          <router-link
            :to="{ name: 'AdminCompanyUsers', params: { id: scope.row.id } }"
          >
            <b>{{ scope.row.users_count }}</b>
          </router-link>
        </template>
      </el-table-column>
      <el-table-column label="Balance" width="150">
        <template slot-scope="scope">
          <h5 class="text-success">
            {{ scope.row.coin }}
            <small v-if="scope.row.coin > 1">Coins</small>
            <small v-else-if="scope.row.coin > 0 && scope.row.coin < 2">
              Coin
            </small>
            <el-button
              class="ml-2"
              type="text"
              @click="selectCompany(scope.row)"
            >
              <i class="fa fa-edit"></i>
            </el-button>
          </h5>
        </template>
      </el-table-column>
      <el-table-column label="Pro Balance" width="150">
        <template slot-scope="scope">
          <h5 style="color: orange;">
            0
            <el-button
              class="ml-2"
              type="text"
              @click="selectCompanyPro(scope.row)"
            >
              <i class="fa fa-edit"></i>
            </el-button>
          </h5>
        </template>
      </el-table-column>
      <el-table-column label="Actions" width="200">
        <template slot-scope="scope">
          <el-tooltip
            effect="dark"
            :content="$t('Invite Teachers')"
            placement="top"
          >
            <el-button
              type="success"
              size="mini"
              @click="setLink(scope.row.invitation_code)"
            >
              <i class="fas fa-user-plus"></i>
            </el-button>
          </el-tooltip>
          <el-tooltip effect="dark" content="Edit" placement="top">
            <el-button
              type="warning"
              size="mini"
              @click="editCompany(scope.$index)"
            >
              <i class="fa fa-edit"></i>
            </el-button>
          </el-tooltip>
          <el-tooltip effect="dark" content="Remove" placement="top">
            <el-button
              type="danger"
              size="mini"
              @click="deleteCompany(scope.row.id)"
            >
              <i class="fa fa-times"></i>
            </el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <div class="text-center mt-3">
      <el-pagination
        background
        @current-change="setPage"
        :page-size="pageSize"
        :current-page="page"
        :total="resultsTotal"
        :pager-count="10"
        layout="prev, pager, next"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="Edit Balance"
      v-if="company"
      :visible.sync="showCompany"
      width="30%"
    >
      <el-form label-width="100px">
        <el-form-item label="Company">
          <b>
            {{ company.name }}
          </b>
        </el-form-item>
        <el-form-item label="Balance">
          <b class="text-success">
            {{ form.oCoins }}
            <small v-if="form.oCoins > 1">Coins</small>
            <small v-else-if="form.oCoins > 0 && form.oCoins < 2">
              Coin
            </small>
          </b>
        </el-form-item>
        <el-form-item label="Type">
          <el-radio-group v-model="form.type">
            <el-radio-button :label="1">Deposit</el-radio-button>
            <el-radio-button :label="0">Deduction</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="COINS">
          <el-input-number
            v-model="form.coins"
            :min="0"
            :max="form.type === 1 ? 2000 : form.oCoins"
            :step="100"
            style="width: 100%;"
          ></el-input-number>
        </el-form-item>
        <el-form-item>
          <div class="text-right">
            <el-button
              type="success"
              :disabled="!(company && form.coins > 0)"
              @click="giveCoins"
            >
              Save
            </el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
      title="Edit Balance"
      v-if="company"
      :visible.sync="showCompanyPro"
      width="30%"
    >
      <el-form label-width="100px">
        <el-form-item label="Company">
          <b>
            {{ company.name }}
          </b>
        </el-form-item>
        <el-form-item label="Balance">
          <b class="text-success">
            {{ form.oCoins }}
            <small v-if="form.oCoins > 1">Coins</small>
            <small v-else-if="form.oCoins > 0 && form.oCoins < 2">
              Coin
            </small>
          </b>
        </el-form-item>
        <el-form-item label="Type">
          <el-radio-group v-model="form.type">
            <el-radio-button :label="1">Deposit</el-radio-button>
            <el-radio-button :label="0">Deduction</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="COINS">
          <el-input-number
            v-model="form.coins"
            :min="0"
            :max="form.type === 1 ? 2000 : form.oCoins"
            :step="100"
            style="width: 100%;"
          ></el-input-number>
        </el-form-item>
        <el-form-item>
          <div class="text-right">
            <el-button
              type="success"
              :disabled="!(company && form.coins > 0)"
              @click="giveCoins"
            >
              Save
            </el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog :visible.sync="showCompanyModal" width="50%">
      <div slot="title">
        {{ companyModalIsEdit ? "Edit" : "Create" }} Company
      </div>
      <el-form label-width="100px">
        <el-form-item label="Name">
          <el-input v-model="newCompany.name"></el-input>
        </el-form-item>
        <el-form-item label="Address">
          <el-input v-model="newCompany.host"></el-input>
        </el-form-item>
        <el-form-item
          label="Admin"
          v-if="companyModalIsEdit && teachers.length > 0"
        >
          <el-select
            style="width: 100%;"
            v-model="newCompany.user_id"
            filterable
            placeholder="Email / Name"
          >
            <el-option
              v-for="(teacher, index) in teachers"
              :key="teacher.id"
              :label="
                `${user.displayName(teacher.first_name, teacher.last_name)} [${
                  teacher.email
                }]`
              "
              :value="teacher.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Introduction">
          <el-input type="textarea" :rows="3" v-model="newCompany.introduction">
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showCompanyModal = false">Cancel</el-button>
        <el-button
          type="success"
          v-if="companyModalIsEdit"
          @click="updateCompany(newCompany.id)"
          >Save</el-button
        >
        <el-button type="success" v-else @click="crateNewCompany">
          Create
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
      class="class-link"
      title="Company Invitation Link"
      :visible.sync="showLink"
      width="50%"
    >
      <div class="text-center">
        <div class="referral-code">
          <a>
            <span>
              {{ link }}
            </span>
          </a>
          <el-button class="ml-3" type="success" @click="copyLink">
            <i class="fas fa-copy"></i>
            {{ $t("toefl.Copy Link") }}
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Breadcrumb from "@/components/Breadcrumb";
import Company from "@/apis/company";
import Admin from "@/apis/admin";
import SAT from "@/apis/sat";
import userApi from "@/apis/user";
import { user, instant } from "@ivy-way/material";
import baseDomainPath from "@/common/baseDomainPath";
export default {
  metaInfo() {
    return {
      title: `Company - ${this.myTitle}`
    };
  },

  components: {
    Breadcrumb
  },

  mixins: [],

  props: [],
  data() {
    return {
      newCompany: {
        name: null,
        user_id: null,
        host: null,
        introduction: null
      },
      form: {
        id: null,
        oCoins: 0,
        coins: 0,
        type: 1
      },
      teachers: [],
      showLink: false,
      link: null,
      company: null,
      showCompany: false,
      companies: null,
      showCompanyModal: false,
      companyModalIsEdit: false,
      resultsTotal: null,
      showRole: false,
      pageSize: 10
    };
  },
  computed: {
    myTitle() {
      return "Companies";
    },
    user() {
      return user;
    },
    instant() {
      return instant;
    },
    page() {
      let page = 1;
      if (this.$route.query.page === undefined) {
        page = 1;
      } else {
        page = this.$route.query.page;
      }
      return parseInt(page);
    }
  },
  watch: {},

  mounted() {
    if (this.$route.query.search) {
      this.studentQuery = this.$route.query.search;
    }
    // this.searchTeachers();
    this.getCompany();
  },

  methods: {
    setLink(code) {
      this.link = `${baseDomainPath}company/join?code=${code}`;
      this.showLink = true;
    },
    copyLink() {
      var oInput = document.createElement("input");
      oInput.value = this.link;
      document.body.appendChild(oInput);
      oInput.select();
      document.execCommand("Copy");
      oInput.className = "oInput";
      oInput.style.display = "none";
      this.showLink = false;
      this.$message({
        message: "Copied",
        type: "success"
      });
    },
    showCompanyModalButton() {
      this.companyModalIsEdit = false;
      this.showCompanyModal = true;
      this.newCompany = {
        name: null,
        user_id: null,
        introduction: null
      };
    },
    editCompany(index) {
      this.companyModalIsEdit = true;
      this.showCompanyModal = true;
      let company = this.companies[index];

      if (this.teachers.length < 1) {
        this.teachers.push(company.user);
      }
      this.newCompany = {
        id: company.id,
        name: company.name,
        user_id: company.user_id,
        host: company.host,
        introduction: company.introduction
      };
      this.getCompanyUsers();
    },
    async deleteCompany(id) {
      this.$confirm(
        "Do you wish to remove this Company from our Website?",
        "",
        {
          confirmButtonText: this.$t("alert.confirm"),
          cancelButtonText: this.$t("alert.cancel"),
          type: "warning"
        }
      ).then(async () => {
        await Company.deleteCompany(id);
        this.getCompany();
        this.$message({
          type: "success",
          message: "Success!"
        });
      });
    },
    async crateNewCompany() {
      await Company.createCompany(this.newCompany);
      this.getCompany();
      this.showCompanyModal = false;
    },
    async updateCompany(id) {
      await Company.updateCompany(id, this.newCompany);
      this.getCompany();
      this.showCompanyModal = false;
    },
    async getCompanyUsers(query) {
      const res = await Admin.getCompanyUsers(this.newCompany.id, {
        keyword: query !== "" ? query : null,
        per_page: 50,
        page: this.page,
        sort: "name"
      });
      this.teachers = res.users.data;
    },
    searchUsers() {
      this.routerPush({
        params: { ...this.$route.params },
        query: {
          ...this.$route.query,
          page: 1,
          search: this.studentQuery !== "" ? this.studentQuery : null
        }
      });
    },
    setPage(page) {
      this.routerPush({
        params: { ...this.$route.params },
        query: { ...this.$route.query, page: page }
      });
    },
    async getCompany() {
      // let query = this.studentQuery;
      this.loading = true;
      const res = await Company.listCompany({
        page: this.page
      });
      this.loading = false;
      this.companies = res.companies.data;
      this.resultsTotal = res.companies.total;
      this.pageSize = res.companies.per_page;
    },
    selectCompany(company) {
      this.company = null;
      this.company = company;
      this.showCompany = true;
      this.form.id = company.id;
      this.form.oCoins = company.coin;
    },
    selectCompanyPro(company) {
      this.company = null;
      this.company = company;
      this.showCompanyPro = true;
      this.form.id = company.id;
      this.form.oCoins = company.coin;
    },
    async giveCoins() {
      if (this.form.type === 1) {
        await Company.giveCoins(this.form.id, {
          coins: this.form.coins
        });
        this.$message({
          message: "Success!",
          type: "success"
        });
      } else {
        await Company.deductCoins(this.form.id, {
          coins: this.form.coins
        });
      }
      this.getCompany();
      this.showCompany = false;
      this.form = {
        id: null,
        type: 1,
        coins: 0,
        oCoins: 0
      };
    }
  }
};
</script>

<style scoped></style>

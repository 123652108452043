import axios from "@/common/interceptors.service";
import ApiService from "@/common/api.service";

export default {
  createCompany(data) {
    return ApiService.post("/company", data);
  },
  updateCompany(id, data) {
    return ApiService.patch(`/company/${id}`, data);
  },
  deleteCompany(id) {
    return ApiService.delete(`/company/${id}`);
  },
  teacherJoinCompany(invitationCode) {
    return ApiService.patch(`/company/join/${invitationCode}`);
  },
  listCompany(data) {
    return ApiService.query("/company", data);
  },
  listCompanyClasses(id, data) {
    return ApiService.query(`/company/${id}/session-classes`, data);
  },
  getCompanyClasses(id, data) {
    return ApiService.query("company/session-classes", data);
  },
  getCompanyInfo() {
    return ApiService.query("company/profile");
  },
  giveCoins(id, data) {
    return ApiService.post(`admin/companies/${id}/give-coins`, data);
  },
  companySetTeacher(data) {
    return ApiService.patch("/company/toggle-teacher", data);
  },
  getUsers(data) {
    return ApiService.query("company/users", data);
  },
  deductCoins(uerId, data) {
    return ApiService.post(`admin/companies/${uerId}/deduct-coins`, data);
  },
  listCompanyTeachers(id, data) {
    return ApiService.query(`/company/${id}/teachers`, data);
  },
  listCompanyExams(id, data) {
    return ApiService.query(`/company/${id}/exams`, data);
  },
  getCompanyTransactions() {
    return ApiService.query("company/coin-transactions");
  }
};
